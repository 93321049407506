import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DocumentNatureService } from 'src/app/services/documentNature.service';
import { DocumentNatureStateModel } from './documentnature.state-model';
import { GetAllDocumentNature, GetDocumentNature, UpdateDocumentNature, CreateDocumentNature } from './documentnature.actions';


@State<DocumentNatureStateModel>({
    name:'DocumentNature'
  })
@Injectable()
  export class DocumentNatureState{
  
    constructor(private service: DocumentNatureService){
  
    }
  
    @Selector()
    static documentNatures(state:DocumentNatureStateModel){
      return state.documentNatures.filter( object => object.active==true);
    }

    @Selector()
    static DocumentNature(state:DocumentNatureStateModel){
      return state.DocumentNature;
    }
  
  
    @Action(GetAllDocumentNature)
    getdocumentNatures({patchState}:StateContext<DocumentNatureStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          documentNatures: result.data
        });
      }));
    }

    @Action(GetDocumentNature)
    getDocumentNatureMethodById({patchState}:StateContext<DocumentNatureStateModel>, {id}: GetDocumentNature){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          DocumentNature: result
        });
      }));
    }
  
    @Action(UpdateDocumentNature)
    updateDocumentNatureMethod({dispatch}:StateContext<DocumentNatureStateModel>,{update}:UpdateDocumentNature
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDocumentNature());
            }
          )
        )
      }

      @Action(CreateDocumentNature)
      createDocumentNature({dispatch, patchState}:StateContext<DocumentNatureStateModel>,{create}:CreateDocumentNature
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDocumentNature());
              }
            )
          )
        }
  
  
    
  
  }