export class GetUnit{
    constructor(public id: number){
    
    }
    
    static readonly type="[Unit] GetUnit";
    
    }
    
    export class GetAllUnit{
        constructor(){
        
        }
        
        static readonly type="[Unit] GetAllUnit";
        
        }
    
    export class UpdateUnit{
        constructor(public update:any){
    
        }
    
        static readonly type="[Unit] UpdateUnit";
    }
        
    export class CreateUnit{
        constructor(public create:any){
    
        }
    
        static readonly type="[Unit] CreateUnit";
    }


