import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DocumentTypeService } from 'src/app/services/documentType.service';
import { DocumentTypeStateModel } from './documenttype.state-model';
import { GetAllDocumentType, GetDocumentType, UpdateDocumentType, CreateDocumentType } from './documenttype.actions';


@State<DocumentTypeStateModel>({
    name:'DocumentType'
  })
@Injectable()
  export class DocumentTypeState{
  
    constructor(private service: DocumentTypeService){
  
    }
  
    @Selector()
    static documentTypes(state:DocumentTypeStateModel){
      return state.documentTypes.filter( object => object.active==true);
    }

    @Selector()
    static DocumentType(state:DocumentTypeStateModel){
      return state.DocumentType;
    }
  
  
    @Action(GetAllDocumentType)
    getdocumentTypes({patchState}:StateContext<DocumentTypeStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          documentTypes: result.data
        });
      }));
    }

    @Action(GetDocumentType)
    getDocumentTypeMethodById({patchState}:StateContext<DocumentTypeStateModel>, {id}: GetDocumentType){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          DocumentType: result
        });
      }));
    }
  
    @Action(UpdateDocumentType)
    updateDocumentTypeMethod({dispatch}:StateContext<DocumentTypeStateModel>,{update}:UpdateDocumentType
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDocumentType());
            }
          )
        )
      }

      @Action(CreateDocumentType)
      createDocumentType({dispatch, patchState}:StateContext<DocumentTypeStateModel>,{create}:CreateDocumentType
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDocumentType());
              }
            )
          )
        }
  
  
    
  
  }