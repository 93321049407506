import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortHistory'
})
export class SortHistoryPipe implements PipeTransform {

  transform(items: any[]): unknown {

    if (!items)
      return [];

    return items.slice().sort((a, b) => Date.parse(b.timeStamp) - Date.parse(a.timeStamp));
  }

}
