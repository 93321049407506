export class GetDocument {
    constructor(public id: number) {

    }

    static readonly type = "[Document] GetDocument";

}


export class ReturnDocument {
    constructor(public documentId: number, public signatoryId: number, public comment: string) {

    }

    static readonly type = "[Document] ReturnDocument";

}

export class ReturnDocumentAuthorizing {
    constructor(public documentId: number) {

    }

    static readonly type = "[Document] ReturnDocumentAuthorizingOfficer";

}






export class GetAllDocument {
    constructor(public params: any) {

    }

    static readonly type = "[Document] GetAllDocuments";

}


export class GetDocumentPDF {
    constructor(public id: number) {

    }

    static readonly type = "[Document] GetDocumentPDF";

}

export class GetExcelReport {
    constructor(public params: any) { }

    static readonly type = "[Document] GetExcelReport";
}

export class GetAttachment {
    constructor(public documentId: number, public attachmentId: number, public name: string) {

    }

    static readonly type = "[User] GetAttachmentID";
}


export class GetAllDocumentPending {
    constructor(public params?: any) {

    }

    static readonly type = "[Document] GetAllDocumentPending";

}


export class GetAllDocumentApproved {
    constructor(public params?: any) {

    }

    static readonly type = "[Document] GetAllDocument";

}


export class UpdateDocument {
    constructor(public documentId: number, public update: any) {

    }

    static readonly type = "[Document] UpdateDocument";
}

export class UpdateService {
    constructor(public documentId: number, public serviceId: number, public update: any) {

    }

    static readonly type = "[Document] UdpateDocumentService";
}

export class SaveDocument {
    constructor(public documentId: number) {

    }

    static readonly type = "[Document] SaveDocument";
}


export class GetAllDocumentUnsaved {
    constructor() {

    }

    static readonly type = "[Document] GetAllDocumentUnsaved";


}


export class CreateDocument {
    constructor(public create: any) {

    }

    static readonly type = "[Document] CreateDocument";
}

export class CreateServiceComment {
    constructor(public documentId: number, public serviceId: number, public comment: any) {

    }

    static readonly type = "[Document] CreateServiceComment";
}

export class DeleteDocument {
    constructor(public id: number) {

    }

    static readonly type = "[Document] DeleteDocument";
}


export class CreateDocumentService {
    constructor(public documentId: number, public create: any) {

    }

    static readonly type = "[Document] CreateDocumentService";
}

export class CreateDocumentNature {
    constructor(public documentId: number, public documentNatureId: number) {

    }

    static readonly type = "[Document] CreateDocumentNature";
}

export class CreateDocumentType {
    constructor(public documentId: number, public documentTypeId: number) {

    }

    static readonly type = "[Document] CreateDocumentType";
}


export class CreateDocumentApproval {
    constructor(public documentId: number, public approval: any) {

    }
    static readonly type = "[Document] CreateDocument Approval";
}

export class CreateDocumentAttachment {
    constructor(public documentId: number, public attachment: any) {

    }
    static readonly type = "[Document] CreateDocument Attachment";
}


export class DeleteDocumentService {
    constructor(public documentId: number, public id: number) {

    }

    static readonly type = "[Document] Delete DocumentService";
}

export class DeleteDocumentNature {
    constructor(public documentId: number, public id: number) {

    }

    static readonly type = "[Document] Delete DocumentNature";
}

export class DeleteDocumentType {
    constructor(public documentId: number, public id: number) {

    }

    static readonly type = "[Document] Delete DocumentType";
}



export class DeleteDocumentAttachment {
    constructor(public documentId: number, public id: number) {

    }
    static readonly type = "[Document] Delete Document Attachment";
}







