import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DocumentService } from 'src/app/services/document.service';
import { DocumentStateModel } from './document.state-model';
import { GetAllDocumentPending, GetDocument, UpdateDocument, CreateDocument, GetDocumentPDF, GetAllDocumentApproved, GetAllDocument, CreateDocumentNature, CreateDocumentService, CreateDocumentType, DeleteDocument, CreateDocumentApproval, CreateDocumentAttachment, DeleteDocumentNature, DeleteDocumentType, DeleteDocumentAttachment, DeleteDocumentService, GetAllDocumentUnsaved, SaveDocument, GetAttachment, GetExcelReport, CreateServiceComment, UpdateService, ReturnDocument, ReturnDocumentAuthorizing } from './document.actions';
import { b64toBlob } from '../../base64toBlob';

@State<DocumentStateModel>({
  name: 'Document'
})
@Injectable()
export class DocumentState {

  constructor(private service: DocumentService) {

  }

  @Selector()
  static documents(state: DocumentStateModel) {
    return state.documents.filter(object => object.active == true);
  }

  @Selector()
  static documentsPending(state: DocumentStateModel) {
    return state.documentsPending.filter(object => object.active == true);
  }

  @Selector()
  static documentsApproved(state: DocumentStateModel) {
    return state.documentsApproved.filter(object => object.active == true);
  }
  @Selector()
  static documentsUnsaved(state: DocumentStateModel) {
    return state.documentsUnsaved.filter(object => object.active == true);
  }

  @Selector()
  static Document(state: DocumentStateModel) {
    return state.Document;
  }


  @Selector()
  static documentPDF(state: DocumentStateModel) {
    return state.documentPDF;
  }



  @Action(GetAllDocumentPending)
  getdocuments({ patchState }: StateContext<DocumentStateModel>, { params }: GetAllDocumentPending) {
    return this.service.getAllPending(params).pipe(tap(result => {
      patchState({
        documentsPending: result.data
      });
    }));
  }

  @Action(GetAllDocumentApproved)
  getdocumentsApproved({ patchState }: StateContext<DocumentStateModel>, { params }: GetAllDocumentApproved) {
    return this.service.getAllApproved(params).pipe(tap(result => {
      patchState({
        documentsApproved: result.data
      });
    }));
  }

  @Action(GetDocument)
  getDocumentMethodById({ patchState }: StateContext<DocumentStateModel>, { id }: GetDocument) {
    return this.service.get(id).pipe(tap(result => {
      patchState({
        Document: result
      });
    }));
  }


  @Action(GetAllDocument)
  getAllDocumentMethod({ patchState }: StateContext<DocumentStateModel>, { params }: GetAllDocument) {
    return this.service.getAll(params).pipe(tap(result => {
      patchState({
        documents: result.data
      });
    }));
  }


  @Action(ReturnDocumentAuthorizing)
  returnDocAuth({ patchState }: StateContext<DocumentStateModel>, { documentId }: ReturnDocumentAuthorizing) {
    return this.service.returnDocumentAuthorizing(documentId).pipe(tap(result => {

    }));
  }


  @Action(ReturnDocument)
  returnDocument({ patchState }: StateContext<DocumentStateModel>, { documentId, signatoryId, comment }: ReturnDocument) {
    return this.service.returnDocument(documentId, signatoryId, comment).pipe(tap(result => {

    }));
  }


  @Action(GetAllDocumentUnsaved)
  getAllDocumentMethodUnsaved({ patchState }: StateContext<DocumentStateModel>) {
    return this.service.getAllUnsaved().pipe(tap(result => {
      patchState({
        documentsUnsaved: result.data
      });
    }));
  }



  @Action(GetDocumentPDF)
  getDocumentMethod({ patchState }: StateContext<DocumentStateModel>, { id }: GetDocumentPDF) {
    return this.service.getPDF(id).pipe(tap(result => {
      var file = new Blob([result], { type: 'application/pdf' });

      var fileURL = URL.createObjectURL(file)


      patchState({
        documentPDF: fileURL
      });
    }));
  }


  @Action(GetAttachment)
  getAttachment({ patchState }: StateContext<DocumentStateModel>, { documentId, attachmentId, name }: GetAttachment) {
    return this.service.getAttachment(documentId, attachmentId).pipe(tap(async result => {

      var base64 = await result.text();
      var content_type = base64.slice(5, base64.indexOf(";"));

      var blob = b64toBlob(base64.substring(base64.indexOf(";") + 1), content_type, 512);

      var a = document.createElement('a');

      var url = URL.createObjectURL(blob);
      a.href = url;


      a.download = name;

      a.click();

      URL.revokeObjectURL(url);

    }));
  }

  @Action(GetExcelReport)
  getExcel({ patchState }: StateContext<DocumentStateModel>, { params }: GetExcelReport) {
    return this.service.getReport(params).pipe(tap(async result => {
      var newBlob = new Blob([result], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = "Document_Report.xlsx";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);


    }));
  }

  @Action(UpdateDocument)
  updateDocumentMethod({ dispatch }: StateContext<DocumentStateModel>, { update, documentId }: UpdateDocument
  ) {
    return this.service.update(documentId, update).pipe(
      tap(
        result => {

        }
      )
    )
  }



  @Action(SaveDocument)
  saveDocument({ dispatch }: StateContext<DocumentStateModel>, { documentId }: SaveDocument
  ) {
    return this.service.save(documentId).pipe(
      tap(
        result => {

        }
      )
    )
  }





  @Action(CreateDocument)
  createDocument({ dispatch, patchState }: StateContext<DocumentStateModel>, { create }: CreateDocument
  ) {
    return this.service.create(create).pipe(
      tap(
        result => {
          patchState({
            Document: result
          });
        }
      )
    )
  }

  @Action(DeleteDocument)
  deleteDocument({ dispatch, patchState }: StateContext<DocumentStateModel>, { id }: DeleteDocument
  ) {
    return this.service.delete(id).pipe(
      tap(
        result => {

        }
      )
    )
  }


  @Action(CreateDocumentNature)
  createDocumentNature({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, documentNatureId }: CreateDocumentNature
  ) {
    return this.service.createNature(documentId, documentNatureId).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }

  @Action(CreateDocumentType)
  createDocumentPriority({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, documentTypeId }: CreateDocumentType
  ) {
    return this.service.createType(documentId, documentTypeId).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }

  @Action(CreateDocumentAttachment)
  createDocumentAttachment({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, attachment }: CreateDocumentAttachment
  ) {
    return this.service.createAttachment(documentId, attachment).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }


  @Action(CreateDocumentService)
  createDocumentService({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, create }: CreateDocumentService
  ) {
    return this.service.createService(documentId, create).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }


  @Action(CreateServiceComment)
  createServiceComment({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, serviceId, comment }: CreateServiceComment
  ) {
    return this.service.createServiceComment(documentId, serviceId, comment).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }


  @Action(CreateDocumentApproval)
  createDocumentApproval({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, approval }: CreateDocumentApproval
  ) {
    return this.service.createApproval(documentId, approval).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }


  @Action(DeleteDocumentNature)
  deleteDocumentNature({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, id }: DeleteDocumentNature
  ) {
    return this.service.deleteNature(documentId, id).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }


  @Action(DeleteDocumentType)
  deleteDocumentType({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, id }: DeleteDocumentType
  ) {
    return this.service.deleteType(documentId, id).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }

  @Action(DeleteDocumentService)
  deleteDocumentService({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, id }: DeleteDocumentService
  ) {
    return this.service.deleteService(documentId, id).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }


  @Action(DeleteDocumentAttachment)
  deleteDocumentAttachment({ dispatch, patchState }: StateContext<DocumentStateModel>, { documentId, id }: DeleteDocumentAttachment
  ) {
    return this.service.deleteAttachment(documentId, id).pipe(
      tap(
        result => {
          patchState({

          });
        }
      )
    )
  }





  @Action(UpdateService)
  updateDocumentService({ dispatch }: StateContext<DocumentStateModel>, { update, documentId, serviceId }: UpdateService
  ) {
    return this.service.updateService(documentId, serviceId, update).pipe(
      tap(
        result => {

        }
      )
    )
  }



}