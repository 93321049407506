import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CurrencyService } from 'src/app/services/currency.service';
import { CurrencyStateModel } from './currency.state-model';
import { GetAllCurrency, GetCurrency, UpdateCurrency, CreateCurrency } from './currency.actions';


@State<CurrencyStateModel>({
    name:'Currency'
  })
@Injectable()
  export class CurrencyState{
  
    constructor(private service: CurrencyService){
  
    }
  
    @Selector()
    static currencies(state:CurrencyStateModel){
      return state.currencies.filter( object => object.active==true).sort((a,b)=> a.name.localeCompare(b.name));
    }

    @Selector()
    static Currency(state:CurrencyStateModel){
      return state.Currency;
    }
  
  
    @Action(GetAllCurrency)
    getcurrencies({patchState}:StateContext<CurrencyStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          currencies: result.data
        });
      }));
    }

    @Action(GetCurrency)
    getCurrencyMethodById({patchState}:StateContext<CurrencyStateModel>, {id}: GetCurrency){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Currency: result
        });
      }));
    }
  
    @Action(UpdateCurrency)
    updateCurrencyMethod({dispatch}:StateContext<CurrencyStateModel>,{update}:UpdateCurrency
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllCurrency());
            }
          )
        )
      }

      @Action(CreateCurrency)
      createCurrency({dispatch, patchState}:StateContext<CurrencyStateModel>,{create}:CreateCurrency
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllCurrency());
              }
            )
          )
        }
  
  
    
  
  }