import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Signin, Signout, VerifyCode, ResetPassword, CheckUsername, CheckEmail, GetLogs, ResetTimer, RefreshToken, EmptyState, Decrement, SelectRole, AppError } from './authentication-actions';
import { catchError, tap } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { Injectable } from '@angular/core';
import { AuthenticationStateModel } from './authentication-state-model';
import { GetUser } from '../user/state/user.actions';
import { AuthenticationService } from 'src/app/services/authentication.service';


@State<AuthenticationStateModel>({
    name: 'authentication' ,
    defaults:{
      timer:0
    }
  })
  @Injectable()
  export class AuthenticationState {

    @Selector()
    static appError(state: AuthenticationStateModel) {
      return state.loginMessage;
    }

    @Selector()
    static accessToken(state: AuthenticationStateModel) {
      return state.jwtToken;
    }

    @Selector()
    static refreshToken(state: AuthenticationStateModel) {
      return state.refreshToken;
    }

    @Selector()
    static group(state:AuthenticationStateModel){
      return state.group;
    }
  
  
    @Selector()
    static id(state: AuthenticationStateModel) {
      return state.id;
    }

    @Selector()
    static time(state: AuthenticationStateModel) {
      return state.timer;
    }
  
    @Selector()
    static authorization(state: AuthenticationStateModel) {
      return state.authorization;
    }

    @Selector()
    static logs(state: AuthenticationStateModel) {
      return state.auditLogs;
    }

    @Selector()
    static logsCount(state: AuthenticationStateModel) {
      return state.auditLogsCount;
    }

    constructor(private service: AuthenticationService) {

    }

    
  @Action(Signin, { cancelUncompleted: true })
  login(
    { patchState, dispatch, getState}: StateContext<AuthenticationStateModel>,
    {payload}: Signin
  ) {
    return this.service.signin(payload).pipe(
      tap(result => {

        if(result){
        let decoded=jwt_decode(result.jwtToken);

        patchState({
          jwtToken: result.jwtToken, 
          refreshToken: result.refreshToken,
          id:parseInt(decoded['nameid'],10),
          authorization: decoded['role'],
          group: decoded['Department'],
          groupId: parseInt(decoded['DepartmentId'],10)
        });

        dispatch(new ResetTimer())
        dispatch(new GetUser(parseInt(decoded['nameid'],10)));
       

      }
      })
    );
  }


  @Action(SelectRole, { cancelUncompleted: true })
  selectRole(
    { patchState, dispatch, getState}: StateContext<AuthenticationStateModel>,
    {roleId}: SelectRole
  ) {
    return this.service.selectRole(roleId).pipe(
      tap(result => {

        if(result){
        let decoded=jwt_decode(result.jwtToken);

        patchState({
          jwtToken: result.jwtToken, 
          refreshToken: result.refreshToken,
          id:parseInt(decoded['nameid'],10),
          authorization: decoded['role'],
          group: decoded['Department'],
          groupId: parseInt(decoded['DepartmentId'],10)
        });

        dispatch(new ResetTimer())
        dispatch(new GetUser(parseInt(decoded['nameid'],10)));
       

      }
      })
    );
  }

  @Action(AppError,  { cancelUncompleted: true })
  async error({patchState}:StateContext<AuthenticationStateModel>,{error}:AppError){

    if(error instanceof Blob){
      var errorMessage = await error.text()
    }

   patchState({
     loginMessage: errorMessage ? errorMessage : error
   })
  }



  @Action(Signout, { cancelUncompleted: true })
  logout({ setState, getState,dispatch }: StateContext<AuthenticationStateModel>,{refreshToken}:Signout) {

    return this.service.signout(refreshToken).pipe(
      
      tap(()=> {

      })


    );


  }

  
  @Action(EmptyState, { cancelUncompleted: true })
  empty({ setState, getState,dispatch }: StateContext<AuthenticationStateModel>) {

 


  


  }


  @Action(RefreshToken, { cancelUncompleted: true })
  refresh({ patchState, getState,dispatch }: StateContext<AuthenticationStateModel>) {

    var state = getState()
    return this.service.refreshToken(state.refreshToken).pipe(
      tap((result)=> {

        if(result){
          let decoded=jwt_decode(result.jwtToken);
  
          patchState({
            jwtToken: result.jwtToken, 
            refreshToken: result.refreshToken,
            id:parseInt(decoded['nameid'],10),
            authorization: decoded['role']
          });

      }})


    );


  }



  @Action(ResetTimer, { cancelUncompleted: true })
  resetTimer({patchState, getState, dispatch }: StateContext<AuthenticationStateModel>) {
        patchState({
          timer: Date.now()+900000
        });

  }



  @Action(GetLogs, { cancelUncompleted: true })
  getlogs({patchState }: StateContext<AuthenticationStateModel>, {params}:GetLogs) {
    return this.service.getlogs(params).pipe(
  
      tap(result => {
        patchState({
          auditLogs: result.data,
          auditLogsCount: result.count
        });
      })

    );


  }
  @Action(VerifyCode,  { cancelUncompleted: true })
  verifyCode({patchState}:StateContext<AuthenticationStateModel>,{payload}:VerifyCode){
    return this.service.verifyCode(payload).pipe(tap(
      result => {

      }));
  }

  @Action(CheckEmail,  { cancelUncompleted: true })
  checkEmail({patchState}:StateContext<AuthenticationStateModel>,{email}:CheckEmail){
    return this.service.checkEmail(email).pipe(tap(
      result => {

      }));
  }

  @Action(CheckUsername,  { cancelUncompleted: true })
  checkUsername({patchState}:StateContext<AuthenticationStateModel>,{username}:CheckUsername){
    return this.service.checkUsername(username).pipe(tap(
      result => {

      }));
  }

  @Action(ResetPassword, { cancelUncompleted: true })
ResetPassword({dispatch}:StateContext<AuthenticationStateModel>,{payload}:ResetPassword){
  return this.service.changePassword(payload).pipe(
    tap(
    result =>{
      
    }
  ))
}

  
  }