export class GetRole{
    constructor(public id: number){
    
    }
    
    static readonly type="[UserRole] GetUserRole";
    
    }
    
    export class GetAllRole{
        constructor(){
        
        }
        
        static readonly type="[UserRole] GetAllUserRole";
        
        }
    
    export class AddRole{
        constructor(public userId:number, public roleId:number, public body: any){
    
        }
    
        static readonly type="[UserRole] AddRole";
    }

    export class DeleteRole{
        constructor(public userId:number, public roleId:number){
    
        }
    
        static readonly type="[UserRole] DeleteRole";
    }
        
    export class CreateRole{
        constructor(public create:any){
    
        }
    
        static readonly type="[UserRole] CreateUserRole";
    }



