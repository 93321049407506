import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { StatusService } from 'src/app/services/status.service';
import { StatusStateModel } from './status.state-model';
import { GetAllStatus, GetStatus, UpdateStatus, CreateStatus } from './status.actions';


@State<StatusStateModel>({
    name:'Status'
  })
@Injectable()
  export class StatusState{
  
    constructor(private service: StatusService){
  
    }
  
    @Selector()
    static statuses(state:StatusStateModel){
      return state.statuses.filter( object => object.active==true).sort();
    }

    @Selector()
    static Status(state:StatusStateModel){
      return state.Status;
    }
  
  
    @Action(GetAllStatus)
    getstatuses({patchState}:StateContext<StatusStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          statuses: result.data
        });
      }));
    }

    @Action(GetStatus)
    getStatusMethodById({patchState}:StateContext<StatusStateModel>, {id}: GetStatus){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Status: result
        });
      }));
    }
  
    @Action(UpdateStatus)
    updateStatusMethod({dispatch}:StateContext<StatusStateModel>,{update}:UpdateStatus
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllStatus());
            }
          )
        )
      }

      @Action(CreateStatus)
      createStatus({dispatch, patchState}:StateContext<StatusStateModel>,{create}:CreateStatus
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllStatus());
              }
            )
          )
        }
  
  
    
  
  }