import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { RoleService } from 'src/app/services/userRole.service';
import { GetAllRole, GetRole, CreateRole, AddRole, DeleteRole } from './userrole.actions';
import { UserRoleStateModel } from './userrole.state-model';

@State<UserRoleStateModel>({
    name:'Role'
  })
@Injectable()
  export class RoleState{
  
    constructor(private service: RoleService){
  
    }
  
    @Selector()
    static Roles(state:UserRoleStateModel){
      return state.Roles.filter( object => object.active==true);
    }

    @Selector()
    static Role(state:UserRoleStateModel){
      return state.Role;
    }
  
  
    @Action(GetAllRole)
    getRoles({patchState}:StateContext<UserRoleStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          Roles: result.data
        });
      }));
    }

    @Action(GetRole)
    getRoleMethodById({patchState}:StateContext<UserRoleStateModel>, {id}: GetRole){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Role: result
        });
      }));
    }
  
    @Action(AddRole)
    addRoleMethod({dispatch}:StateContext<UserRoleStateModel>,{userId,roleId, body}:AddRole
      ){
        return this.service.addRole(userId,roleId,body).pipe(
          tap(
            result=>
            {
             
            }
          )
        )
      }


      @Action(DeleteRole)
      removeRole({dispatch}:StateContext<UserRoleStateModel>,{userId,roleId}:DeleteRole
        ){
          return this.service.removeRole(userId,roleId).pipe(
            tap(
              result=>
              {
               
              }
            )
          )
        }

      @Action(CreateRole)
      createRole({dispatch, patchState}:StateContext<UserRoleStateModel>,{create}:CreateRole
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllRole());
              }
            )
          )
        }
  
  
    
  
  }


