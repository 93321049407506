<clr-main-container>
    <app-header></app-header>
    <div class="content-container">

        <div class="content-area" *ngIf="id" style="padding:1%;">
            <div class="clr-row clr-justify-content-center">
                <div class="clr-col">
                    <span>
                        <h3 style="font-weight:bold;">Quick Links</h3>
                    </span>
                </div>
                <div class="clr-break-row">
                    <hr>
                </div>

            </div>
            <div class="clr-row clr-justify-content-center ">

                <div class="clr-col-10">
                    <div class="clr-row">
                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-12" *ngIf="role != 'Admin'">
                            <a class="card clickable"  routerLink="/request/view-investment" style="text-align: center;">

                                <div class="card-block" style="opacity:0.8;">
                                    <img src="assets/pending-form.png" style="height:100px;width:100px;">
                                    <div class="card-title">
                                        Pending Requests
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-12" *ngIf="role != 'Admin'">
                            <a class="card clickable"  routerLink="/request/view-investment-approved"
                                style="text-align: center;">

                                <div class="card-block" style="opacity:0.8;">
                                    <img src="assets/approved-form.png" style="height:100px;width:100px;">
                                    <div class="card-title">
                                        Approved Requests
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-12" *ngIf="role == 'Requesting Officer'">
                            <a class="card clickable"  routerLink="/apply/investment-requests" style="text-align: center;">

                                <div class="card-block" style="opacity:0.8;">
                                    <img src="assets/apply.png" style="height:100px;width:100px;">
                                    <div class="card-title">
                                        Services Application
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-12"
                            *ngIf="role =='Admin' ">
                            <a class="card clickable"  routerLink="/management/" style="text-align: center;">

                                <div class="card-block" style="opacity:0.8;">
                                    <img src="assets/records.png" style="height:100px;width:100px;">
                                    <div class="card-title">
                                        Manage Records
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-12"
                            *ngIf=" role == 'Procurement' ">
                            <a class="card clickable"  routerLink="/management/vendors" style="text-align: center;">

                                <div class="card-block" style="opacity:0.8;">
                                    <img src="assets/records.png" style="height:100px;width:100px;">
                                    <div class="card-title">
                                        Manage Records
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-12"
                            *ngIf="role=='Bursary' ">
                            <a class="card clickable"  routerLink="/management/funds" style="text-align: center;">

                                <div class="card-block" style="opacity:0.8;">
                                    <img src="assets/records.png" style="height:100px;width:100px;">
                                    <div class="card-title">
                                        Manage Records
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-12">
                            <a class="card clickable" routerLink="/authentication/my-account"  style="text-align: center;">


                                <div class="card-block" style="opacity:0.8;">
                                    <img src="assets/user.png" style="height:100px;width:100px;">
                                    <div class="card-title">
                                        Manage Account
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>

</clr-main-container>