import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select, Actions, ofActionSuccessful, ofActionDispatched, ofActionErrored, ofActionCompleted } from '@ngxs/store';
import { Observable, Subject, timer } from 'rxjs';
import { AuthenticationState } from 'src/app/store/authentication/authentication-state';
import { EmptyState, ResetTimer, SelectRole, Signout } from 'src/app/store/authentication/authentication-actions';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserState } from 'src/app/store/user/state/user.state';
import { User } from 'src/app/models/user';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {


  destroy$ = new Subject<void>();

  @Select(UserState.User) user$: Observable<User>;
  user: User;

  @Select(AuthenticationState.group) department$: Observable<string>;
  department: string;

  role: string;

  finishSignUp = false;
  @Select(AuthenticationState.time) time$: Observable<number>;
  time: number;
  interval;

  timeLeft: number = null;

  timeRunningOutModal: boolean = false;

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.destroy$.next();
    this.destroy$.complete();
  }

  @Select(AuthenticationState.authorization) type$: Observable<string>;
  userStatus: boolean;


  constructor(private router: Router, private store: Store, private actions: Actions) {
    this.type$.subscribe(role => this.role = role);
    this.user$.subscribe(user => this.user = user);
    this.time$.subscribe(time => this.time = time);
    this.department$.subscribe(department => this.department = department);
  }


  setRole() {
    this.role = this.store.selectSnapshot(AuthenticationState.authorization);
  }



  signUpComplete() {

    if (this.router.url != "/authentication/my-account") {
      if (this.role != "Requesting Officer" && this.role != "Admin") {
        if (this.user?.signature == null)
          this.finishSignUp = true;
      }
    }
  }

  logout() {
    localStorage.removeItem('authentication');
    this.store.dispatch(new Signout(this.store.selectSnapshot(AuthenticationState.refreshToken)));
  }

  myAccount() {
    this.router.navigate(['/authentication/my-account'])
  }

  switchRole(id: number) {
    this.store.dispatch(new SelectRole(id)).subscribe(() => this.router.navigate(['/timeline'])
      .then(() => {
        window.location.reload();
      }));
  }

  ngOnInit() {

    this.setRole();
    this.signUpComplete();
    this.actions.pipe(ofActionCompleted(Signout), takeUntil(this.destroy$)).subscribe(() => { this.router.navigate(['/authentication/login']); });

    this.interval = setInterval(() => {

      this.timeLeft = Math.trunc((this.time - Date.now()) / 1000);

      if (this.timeLeft == 120) {

        this.timeRunningOutModal = true;

      }
      else if (this.timeLeft > 0) {

      } else {
        this.logout()
      }
    }, 1000)



  }

}
