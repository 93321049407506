<clr-main-container>
    <app-header></app-header>
    <div class="content-container">
        <clr-vertical-nav [clr-nav-level]="2" *ngIf="role !='Admin'" [clrVerticalNavCollapsible]="collapsible">
            <clr-tree>
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="note"></clr-icon>
                    <b class="clr-treenode-link">Requests</b>
                    <clr-tree-node [clrExpanded]="true">
                        <b class="clr-treenode-link">
                            Investment Requests
                        </b>
                        <clr-tree-node>
                            <a class="clr-treenode-link" routerLink="/request/view-investment"
                                routerLinkActive="active">
                                <span>Pending</span>
                            </a>
                        </clr-tree-node>
                        <clr-tree-node>
                            <a class="clr-treenode-link" routerLink="/request/view-investment-approved"
                                routerLinkActive="active">
                                <span>Approved/Rejected</span>
                            </a>
                        </clr-tree-node>
                    </clr-tree-node>

                </clr-tree-node>

            </clr-tree>

        </clr-vertical-nav>
        <div class="content-area" *ngIf="id">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>