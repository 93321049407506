import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { CanvasComponent } from './canvas/canvas.component';
import { SortHistoryPipe } from './sort-history.pipe';
import { FacultyDepartmentPipePipe } from './faculty-department-pipe.pipe';
import { SafePipe } from './safe.pipe';
import { OrderByPipe } from './order-by.pipe';
import { StatusPipe } from './status.pipe';


@NgModule({
  declarations: [HeaderComponent, PasswordStrengthBarComponent, SortHistoryPipe, CanvasComponent, FacultyDepartmentPipePipe, SafePipe, OrderByPipe, StatusPipe],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
    
  ],
  exports:[
    HeaderComponent,
    PasswordStrengthBarComponent,
    CanvasComponent,
    SortHistoryPipe,
    FacultyDepartmentPipePipe,
    SafePipe,
    OrderByPipe,
    StatusPipe
    
  ]
})

export class SharedModule { }
