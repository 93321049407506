export class GetFund {
    constructor(public id: number) {

    }

    static readonly type = "[Fund] GetFund";

}

export class GetAllFund {
    constructor() {

    }

    static readonly type = "[Fund] GetAllFund";

}

export class UpdateFund {
    constructor(public update: any) {

    }

    static readonly type = "[Fund] UpdateFund";
}


export class DeleteFund {
    constructor(public fundId: number) {

    }

    static readonly type = "[Fund] DeleteFund";
}

export class CreateFund {
    constructor(public create: any) {

    }

    static readonly type = "[Fund] CreateFund";
}


