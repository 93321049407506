import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ServiceService } from 'src/app/services/service.service';
import { ServiceStateModel } from './service.state-model';
import { GetAllService, GetService, UpdateService, CreateService, AddVendor } from './service.actions';


@State<ServiceStateModel>({
  name: 'Service'
})
@Injectable()
export class ServiceState {

  constructor(private service: ServiceService) {

  }

  @Selector()
  static services(state: ServiceStateModel) {
    return state.services.filter(object => object.active == true);
  }

  @Selector()
  static Service(state: ServiceStateModel) {
    return state.Service;
  }


  @Action(GetAllService)
  getservices({ patchState }: StateContext<ServiceStateModel>) {
    return this.service.getAll().pipe(tap(result => {
      patchState({
        services: result.data
      });
    }));
  }

  @Action(GetService)
  getServiceMethodById({ patchState }: StateContext<ServiceStateModel>, { id }: GetService) {
    return this.service.get(id).pipe(tap(result => {
      patchState({
        Service: result
      });
    }));
  }


  @Action(AddVendor)
  addVendorToService({ patchState }: StateContext<ServiceStateModel>, { serviceId, vendorId }: AddVendor) {
    return this.service.addVendor(serviceId, vendorId).pipe(tap(result => {

    }));
  }



  @Action(CreateService)
  createService({ dispatch, patchState }: StateContext<ServiceStateModel>, { create }: CreateService
  ) {
    return this.service.create(create).pipe(
      tap(
        result => {
          dispatch(new GetAllService());
        }
      )
    )
  }




}