import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { appState } from './store';
import { environment } from 'src/environments/environment';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { logoutPlugin } from './store/logout-reducer';
import { TimelineComponent } from './timeline/timeline.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    TimelineComponent,

  ],
  imports: [
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule, 
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    NgxsModule.forRoot(appState, {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({key:['authentication','User']}),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }
    ),
  
  ],
  providers: [
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptor,
      multi:true
    },
      {
        provide: NGXS_PLUGINS,
        useValue: logoutPlugin,
        multi: true
      }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

