import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { throwError, Observable, of, fromEvent } from 'rxjs';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';
import { api } from './api-link';
import { AuthenticationState } from '../store/authentication/authentication-state';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  constructor(private http: HttpClient,private _store:Store) {
  }

  httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json'
     })
   }

   errorHandl(error) {
   let errorMessage = '';
   if(error.error instanceof ErrorEvent) {
     // Get client-side error
     errorMessage = error.error.message;
   } else {
     // Get server-side error
     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
   }
   console.log(errorMessage);
   return throwError(errorMessage);
}

private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
get isOnline() {
  return navigator.onLine;
}

signin(credentials):Observable<any> {
  return this.http.post(api+'/user/authenticate',
    JSON.stringify(credentials),this.httpOptions).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    );

}

selectRole(roleId):Observable<any> {
  return this.http.post(api+'/user/selectRole',
    JSON.stringify(roleId),this.httpOptions).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    );

}





checkEmail(email: string) {
  return this.http.post(api+'/User/Email',
  JSON.stringify(email),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}

checkUsername(username: string) {
  return this.http.post(api+'/User/Username',
  JSON.stringify(username),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}

changePassword(payload):Observable<any>{
  return this.http.post(api+'/User/RequestPasswordChange',
  JSON.stringify(payload),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}

verifyCode(payload):Observable<any>{

  return this.http.post(api+'/user/ResetPassword',
  JSON.stringify(payload),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );

}

getlogs(params: any):Observable<any>{

  return this.http.get(api+'/AuditLog',{params:params}).pipe(
    retry(1),
    catchError(this.errorHandl)
  );

}


signout(refreshToken:string): Observable<any> {
  return this.http.post<any>(api+'/User/RevokeToken',JSON.stringify(refreshToken),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}

refreshToken(refreshToken:string): Observable<any> {
  return this.http.post<any>(api+'/User/RefreshToken',JSON.stringify(refreshToken),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}

}