import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SignatoryService } from 'src/app/services/signatory.service';
import { SignatoryStateModel } from './signatory.state-model';
import { GetAllSignatory, GetSignatory, UpdateSignatory, CreateSignatory, DeleteSignatory, CreateSignatoryException, DeleteSignatoryException, UpdateExemption } from './signatory.actions';


@State<SignatoryStateModel>({
    name:'Signatory'
  })
@Injectable()
  export class SignatoryState{
  
    constructor(private service: SignatoryService){
  
    }
  
    @Selector()
    static signatories(state:SignatoryStateModel){
      return state.signatories.filter( object => object.active==true).sort();
    }

    @Selector()
    static Signatory(state:SignatoryStateModel){
      return state.Signatory;
    }
  
  
    @Action(GetAllSignatory)
    getsignatories({patchState}:StateContext<SignatoryStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          signatories: result.data
        });
      }));
    }

    @Action(GetSignatory)
    getSignatoryMethodById({patchState}:StateContext<SignatoryStateModel>, {id}: GetSignatory){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Signatory: result
        });
      }));
    }
  
    @Action(UpdateSignatory)
    updateSignatoryMethod({dispatch}:StateContext<SignatoryStateModel>,{signatoryId,update}:UpdateSignatory
      ){
        return this.service.update(signatoryId,update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllSignatory());
            }
          )
        )
      }

      @Action(UpdateExemption)
      updateExemption({dispatch}:StateContext<SignatoryStateModel>,{exemptionId,update}:UpdateExemption
        ){
          return this.service.updateExemption(exemptionId,update).pipe(
            tap(
              result=>
              {
               dispatch(new GetAllSignatory());
              }
            )
          )
        }

      @Action(DeleteSignatory)
      delete({dispatch}:StateContext<SignatoryStateModel>,{id}:DeleteSignatory
        ){
          return this.service.delete(id).pipe(
            tap(
              result=>
              {
             
              }
            )
          )
        }

        @Action(DeleteSignatoryException)
        deleteException({dispatch}:StateContext<SignatoryStateModel>,{signatoryId, exceptionId}:DeleteSignatoryException
          ){
            return this.service.deleteException(signatoryId,exceptionId).pipe(
              tap(
                result=>
                {
               
                }
              )
            )
          }
  

      @Action(CreateSignatory)
      createSignatory({dispatch, patchState}:StateContext<SignatoryStateModel>,{create}:CreateSignatory
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllSignatory());
              }
            )
          )
        }

        @Action(CreateSignatoryException)
        addSignatoryException({dispatch, patchState}:StateContext<SignatoryStateModel>,{signatoryId, create}:CreateSignatoryException
          ){
            return this.service.createException(signatoryId,create).pipe(
              tap(
                result=>
                {
                  
                }
              )
            )
          }
  
  
    
  
  }