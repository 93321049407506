import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticationState } from 'src/app/store/authentication/authentication-state';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  collapsible: boolean=true;

  @Select(AuthenticationState.authorization) role$:Observable<string>;
  role:string;

  @Select(AuthenticationState.id) id$:Observable<number>;
  id:number;
  
  

  constructor(private router: Router) { 
    this.role$.subscribe(role => this.role=role);
    this.id$.subscribe(id => this.id=id);
  }

  ngOnInit(): void { }

}
