import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Vendor } from '../models/vendor';
import { throwError, Observable, fromEvent } from 'rxjs';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';
import { api } from './api-link';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));

  get isOnline() {
    return navigator.onLine;
  }


  constructor(private http: HttpClient) { }

  errorHandl(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  get(id: number): Observable<Vendor> {
    return this.http.get<Vendor>(api + "/Vendor/" + id).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  getAll(): Observable<any> {
    return this.http.get<Vendor[]>(api + "/Vendor/").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  update(update: any): Observable<Vendor> {
    return this.http.patch<Vendor>(api + '/Vendor/', JSON.stringify(update), this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  create(create: any): Observable<Vendor> {
    return this.http.post<Vendor>(api + '/Vendor/', JSON.stringify(create), this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }



}