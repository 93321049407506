<clr-main-container>
    <app-header></app-header>
    <div class="content-container">
        <clr-vertical-nav [clr-nav-level]="2">
            <clr-tree>
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="note"></clr-icon>
                    <b>Lists</b>
                    <clr-tree-node *ngIf="role == 'Admin' ">
                        <a class="clr-treenode-link" routerLink="/management/users" routerLinkActive="active">
                            Users
                        </a>
                    </clr-tree-node>
                    <clr-tree-node *ngIf="role == 'Admin' ">
                        <a class="clr-treenode-link" routerLink="/management/documents" routerLinkActive="active">
                            Documents
                        </a>
                    </clr-tree-node>
                    <clr-tree-node *ngIf="role =='Bursary' ">
                        <a class="clr-treenode-link" routerLink="/management/funds" routerLinkActive="active">
                            Funds
                        </a>
                    </clr-tree-node>
                    <clr-tree-node *ngIf="role == 'Procurement' || role =='Bursary' ">
                        <a class="clr-treenode-link" routerLink="/management/vendors" routerLinkActive="active">
                            Vendors
                        </a>
                    </clr-tree-node>
                    <clr-tree-node *ngIf="role == 'Admin' ">
                        <a class="clr-treenode-link" routerLink="/management/faculties" routerLinkActive="active">
                            Faculties
                        </a>
                    </clr-tree-node>
                    <clr-tree-node *ngIf="role == 'Admin' ">
                        <a class="clr-treenode-link" routerLink="/management/departments" routerLinkActive="active">
                            Departments
                        </a>
                    </clr-tree-node>
                    <clr-tree-node *ngIf="role == 'Admin' ">
                        <a class="clr-treenode-link" routerLink="/management/request-types" routerLinkActive="active">
                            Request Types
                        </a>
                    </clr-tree-node>

                    <clr-tree-node *ngIf="role == 'Admin' ">
                        <a class="clr-treenode-link" routerLink="/management/logs" routerLinkActive="active">
                            Audit Logs
                        </a>
                    </clr-tree-node>

                </clr-tree-node>

            </clr-tree>

        </clr-vertical-nav>
        <div class="content-area" *ngIf="id">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>