export class GetSignatory{
    constructor(public id: number){
    
    }
    
    static readonly type="[Signatory] GetSignatory";
    
    }
    
    export class GetAllSignatory{
        constructor(){
        
        }
        
        static readonly type="[Signatory] GetAllSignatory";
        
        }
    
    export class UpdateSignatory{
        constructor(public signatoryId: number, public update:any){
    
        }
    
        static readonly type="[Signatory] UpdateSignatory";
    }


        
    export class UpdateExemption{
        constructor(public exemptionId:number,public update:any){
    
        }
    
        static readonly type="[Exemption] UpdateExemption";
    }

    export class DeleteSignatory{
        constructor(public id:number){
    
        }
    
        static readonly type="[Signatory] DeleteSignatory";
    }

    
    export class DeleteSignatoryException{
        constructor(public exceptionId:number, public signatoryId:number){
    
        }
    
        static readonly type="[Signatory] DeleteSignatoryException";
    }
        
    export class CreateSignatory{
        constructor(public create:any){
    
        }
    
        static readonly type="[Signatory] CreateSignatory";
    }

    export class CreateSignatoryException{
        constructor(public signatoryId:number, public create:any){
    
        }
    
        static readonly type="[Signatory] CreateSignatoryException";
    }


