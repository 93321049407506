export class GetStatus{
    constructor(public id: number){
    
    }
    
    static readonly type="[Status] GetStatus";
    
    }
    
    export class GetAllStatus{
        constructor(){
        
        }
        
        static readonly type="[Status] GetAllStatus";
        
        }
    
    export class UpdateStatus{
        constructor(public update:any){
    
        }
    
        static readonly type="[Status] UpdateStatus";
    }
        
    export class CreateStatus{
        constructor(public create:any){
    
        }
    
        static readonly type="[Status] CreateStatus";
    }


