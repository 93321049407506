<clr-alert clrAlertType="warning" clrAlertAppLevel="true" [clrAlertClosable]="false">
    <clr-alert-item style="color:white;">{{timeLeft}} seconds left before logout for inactivity. Perform an action to
        stay logged in...
    </clr-alert-item>
</clr-alert>

<clr-header style="background-color:green;overflow: hidden;">
    <div class="branding">


        <a href="..." class="nav-link" routerLink="/timeline" >
            <img src="../../../assets/ministry-header-logo.png" style="height: 90%;width:auto;margin:auto">
        </a>

        <span class="title" style="font-weight: bolder;">Investment Request Information System(IRIS)</span>
    </div>

    <div class="header-nav" [clr-nav-level]="1">
        <a href="..." class="nav-link" routerLink="/timeline" routerLinkActive="active"><span
                class="nav-text">Home</span></a>
        <a href="..." class="nav-link" routerLink="/apply" routerLinkActive="active"
            *ngIf="role == 'Requesting Officer'"><span class="nav-text">Applications</span></a>
        <a href="..." class="nav-link" routerLink="/request" routerLinkActive="active" *ngIf="role!='Admin'"><span
                class="nav-text">Requests</span></a>
        <a href="..." class="nav-link" routerLink="/management" routerLinkActive="active" *ngIf="role =='Admin'"><span
                class="nav-text">Management</span></a>
        <a href="..." class="nav-link" routerLink="/management/vendors" routerLinkActive="active"
            *ngIf="role == 'Procurement'"><span class="nav-text">Management</span></a>
        <a href="..." class="nav-link" routerLink="/management/funds" routerLinkActive="active"
            *ngIf="role=='Bursary' "><span class="nav-text">Management</span></a>
    </div>

    <div class="header-actions">

        <clr-dropdown>
            <button class="nav-text" clrDropdownTrigger aria-label="open user profile">
                <span class="hide-mobile">{{role}} - {{department}}</span> <span class="mobile"
                    style="font-size:smaller">{{role}} - {{ (department?.length>6)? (department |
                    slice:0:5)+'..':(department) }}</span>
                <clr-icon shape="caret down"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <button *ngFor="let role of user.userRole" clrDropdownItem (click)="switchRole(role.userRoleId)">
                    {{role.role?.name}} - {{role.department?.name}}
                </button>

            </clr-dropdown-menu>
        </clr-dropdown>

        <clr-dropdown>
            <button class="nav-text" clrDropdownTrigger aria-label="open user profile">

                <span class="hide-mobile"> {{user?.firstName}} {{user?.lastName}}, ID: {{user?.userId}}</span>
                <clr-icon shape="user"></clr-icon>
                <clr-icon shape="caret down"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <button clrDropdownItem (click)="myAccount()">
                    My Account
                </button>
                <button clrDropdownItem (click)="logout()">
                    Log out
                </button>

            </clr-dropdown-menu>
        </clr-dropdown>
    </div>
</clr-header>


<clr-modal [(clrModalOpen)]="finishSignUp" [clrModalClosable]="false">
    <h3 class="modal-title">Complete Sign Up</h3>
    <div class="modal-body">
        <p>To finish the sign up process you must enter a valid signature for signing documents.
            <br>Press 'Update' below and update your account data to finish the signup process.
        </p>
        <div class="modal-footer">
            <button routerLink="/authentication/my-account" class="btn btn-primary">Update</button>
        </div>
    </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="timeRunningOutModal" [clrModalClosable]="false">
    <h3 class="modal-title">Inactivity Alert</h3>
    <div class="modal-body">
        <p> You are about to be logged out for inactivity in {{timeLeft}} seconds.
            <br>Complete any action to avoid being logged out.
        </p>
        <div class="modal-footer">
            <button (click)="timeRunningOutModal = false" class="btn btn-primary">Ok</button>
        </div>
    </div>
</clr-modal>