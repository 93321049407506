import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { RequestTypeService } from 'src/app/services/requestType.service';
import { RequestTypeStateModel } from './requesttype.state-model';
import { GetAllRequestType, GetRequestType, UpdateRequestType, CreateRequestType, DeleteRequestType, CreateRequestTypeCategory, DeleteRequestTypeCategory } from './requesttype.actions';


@State<RequestTypeStateModel>({
  name: 'RequestType'
})
@Injectable()
export class RequestTypeState {

  constructor(private service: RequestTypeService) {

  }

  @Selector()
  static requestTypes(state: RequestTypeStateModel) {
    return state.requestTypes.filter(object => object.active == true).sort();
  }

  @Selector()
  static RequestType(state: RequestTypeStateModel) {
    return state.RequestType;
  }


  @Action(GetAllRequestType)
  getrequestTypes({ patchState }: StateContext<RequestTypeStateModel>) {
    return this.service.getAll().pipe(tap(result => {
      patchState({
        requestTypes: result.data
      });
    }));
  }

  @Action(GetRequestType)
  getRequestTypeMethodById({ patchState }: StateContext<RequestTypeStateModel>, { id }: GetRequestType) {
    return this.service.get(id).pipe(tap(result => {
      patchState({
        RequestType: result
      });
    }));
  }

  @Action(UpdateRequestType)
  updateRequestTypeMethod({ dispatch }: StateContext<RequestTypeStateModel>, { update }: UpdateRequestType
  ) {
    return this.service.update(update).pipe(
      tap(
        result => {
          dispatch(new GetAllRequestType());
        }
      )
    )
  }

  @Action(DeleteRequestType)
  delete({ dispatch }: StateContext<RequestTypeStateModel>, { id }: DeleteRequestType
  ) {
    return this.service.delete(id).pipe(
      tap(
        result => {
          dispatch(new GetAllRequestType());
        }
      )
    )
  }

  @Action(CreateRequestType)
  createRequestType({ dispatch, patchState }: StateContext<RequestTypeStateModel>, { create }: CreateRequestType
  ) {
    return this.service.create(create).pipe(
      tap(
        result => {
          dispatch(new GetAllRequestType());
        }
      )
    )
  }


  @Action(CreateRequestTypeCategory)
  createRequestTypeCategory({ dispatch, patchState }: StateContext<RequestTypeStateModel>, { requestTypeId, create }: CreateRequestTypeCategory
  ) {
    return this.service.createCategory(requestTypeId, create).pipe(
      tap(
        result => {

        }
      )
    )
  }



  @Action(DeleteRequestTypeCategory)
  deleteRequestTypeCategory({ dispatch, patchState }: StateContext<RequestTypeStateModel>, { requestTypeId, categoryId }: DeleteRequestTypeCategory
  ) {
    return this.service.deleteCategory(requestTypeId, categoryId).pipe(
      tap(
        result => {

        }
      )
    )
  }




}