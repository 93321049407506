import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Signatory } from '../models/signatory';
import { throwError, Observable, fromEvent } from 'rxjs';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';
import { api } from './api-link';

@Injectable({
  providedIn: 'root'
})
export class SignatoryService {



    private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
    get isOnline() {
    return navigator.onLine;
    }


  constructor(private http:HttpClient) { }

  errorHandl(error:any) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

httpOptions={
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}

  get(id:number):Observable<Signatory>{
    return this.http.get<Signatory>(api+"/Signatory/"+id).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  getAll():Observable<any>{
    return this.http.get<any>(api+"/Signatory/").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  update(id:number,update: any):Observable<Signatory>{
    return this.http.patch<Signatory>(api+'/Signatory/'+id,JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  updateExemption(id:number,update: any):Observable<any>{
    return this.http.patch<any>(api+'/Exemption/'+id,JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  delete(id: number):Observable<Signatory>{
    return this.http.delete<Signatory>(api+'/Signatory/'+id,this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  deleteException(signatoryId: number, exceptionId: number) {
    return this.http.delete<Signatory>(api+'/Signatory/'+signatoryId+'/Exemption/'+exceptionId,this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }


    create(create: any):Observable<Signatory>{
      return this.http.post<Signatory>(api+'/Signatory/',JSON.stringify(create),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }

    createException(signatoryId: number, create: any): Observable<Signatory> {
      return this.http.post<Signatory>(api+'/Signatory/'+signatoryId+'/exemption',JSON.stringify(create),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }



}